import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  ContentWrapper,
  Title,
  HorizontalDivider,
  Description,
  OffersLink,
  ImageWrapper,
  TransitionWrapper,
  TransitionBlock,
  DescriptionItem,
  DescriptionText
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import ImageSlider from "../../../../../common/ImageSlider";
import { PageThemeType } from "../../../../../../utils/types";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

const transitionBlocks = [...Array(20)].map((_, index) => (
  <TransitionBlock key={index} className="whiteBlock" />
));

interface AboutUsProps {
  pageChange: PageChange;
  goToPage?: (newPage: number) => void;
  navPageThemeChange?: (theme?: PageThemeType) => void;
  scrollPageThemeChange?: (theme?: PageThemeType) => void;
}

const AboutUs: React.FC<AboutUsProps> = ({
  pageChange,
  goToPage,
  navPageThemeChange,
  scrollPageThemeChange,
}) => {
  const { t } = useTranslation("career");
  const data = useStaticQuery(backgroundQuery);

  useEffect(() => {
    if (isTablet) {
      document.body.addEventListener("scroll", handleScroll, { passive: true });
      if (document.body.scrollTop <= 0) {
        setTimeout(() => {
          navPageThemeChange && navPageThemeChange("white");
          scrollPageThemeChange && scrollPageThemeChange("white");
        });
      }

      return () => {
        document.body.removeEventListener("scroll", handleScroll);
        navPageThemeChange && navPageThemeChange(undefined);
        scrollPageThemeChange && scrollPageThemeChange(undefined);
      };
    }
  }, [isTablet]);

  const handleScroll = () => {
    const page = document
      .getElementById("aboutUsWhiteWrapper")
      ?.getBoundingClientRect();
    const pageBlue = document
      .getElementById("aboutUsBlueWrapper")
      ?.getBoundingClientRect();
    const pageIndicator = document.getElementsByClassName("pageIndicator")[1];
    const navPadding = 88;
    const scrollPadding = pageIndicator?.getBoundingClientRect().top;
    if (page && Math.abs(page.y - navPadding) < page.height) {
      navPageThemeChange && navPageThemeChange("white");
    } else if (
      pageBlue &&
      pageBlue.y < navPadding &&
      Math.abs(pageBlue.y - navPadding) < pageBlue.height
    ) {
      navPageThemeChange && navPageThemeChange("blue");
    } else {
      navPageThemeChange && navPageThemeChange(undefined);
    }

    if (
      page &&
      page.y < scrollPadding &&
      page.height + page.y > scrollPadding
    ) {
      scrollPageThemeChange && scrollPageThemeChange("white");
    } else {
      scrollPageThemeChange && scrollPageThemeChange("blue");
    }
  };

  const goToOffers = () => {
    goToPage && goToPage(4);
  };

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`aboutUsPage_title`))} />
      <PageWrapper animation={pageChange}>
        <ContentWrapper id="aboutUsWhiteWrapper">
          <Title>{t(`aboutUs_title`)}</Title>
          <HorizontalDivider />
          <Description>
            {(t(`aboutUs_description`) as unknown as string[]).map(e => 
            <DescriptionItem>
             &middot; <DescriptionText>{e}</DescriptionText>
            </DescriptionItem>)}
          </Description>
          <OffersLink onClick={goToOffers}>
            {t(`aboutUs_goToOffers`)}
          </OffersLink>
        </ContentWrapper>
        <ImageWrapper id="aboutUsBlueWrapper">
          <TransitionWrapper>{transitionBlocks}</TransitionWrapper>
          <ImageSlider images={data && data.allFile.nodes} />
        </ImageWrapper>
      </PageWrapper>
    </>
  );
};

const backgroundQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "aboutUs" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default AboutUs;
