import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideToRight,
} from "../../../../../../utils/animations";
import {
  H2,
  H3,
  H4,
  H6,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    flex-direction: column;
  } ;
`;

export const ContentWrapper = styled.div`
  width: 50%;
  padding: 80px 64px 64px 64px;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
    padding: 88px 178px 48px 88px;
  }

  @media ${device.computer} {
    padding: 88px 0 80px 120px;
    display: grid;
    grid-template-columns: repeat(5, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    padding: 128px 0 235px 120px;
    grid-template-columns: repeat(5, 110px);
    height: 100vh;
  } ;
`;

export const Title = styled.div`
  ${H4}
  margin: 0;
  color: #151515;
  white-space: pre-line;

  @media ${device.computer} {
    ${H3}
    display: grid;
    grid-column: 1 / 6;
    width: 100%;
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const HorizontalDivider = styled.hr`
  margin: 40px 0 48px 0;
  width: 144px;
  min-height: 5px;
  background: #013cff;

  @media ${device.computer} {
    display: none;
  } ;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  display: block;
  color: #151515;
  white-space: pre-line;

  @media ${device.computer} {
    margin-top: 48px;
    grid-column: 1 / 5;
  }

  @media ${device.desktop} {
  }
`;

export const DescriptionItem = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
`;

export const DescriptionText = styled.div`
  padding-left: 5px;
`;

export const OffersLink = styled.span`
  ${H6}
  margin-top: auto;
  text-transform: uppercase;
  text-decoration: none;
  color: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-top: 88px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${device.computer} {
    margin-top: 80px;
    grid-column: 1 / 6;
  } ;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 50%;
  z-index: -1;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
  }

  @media ${device.computer} {
    width: 55%;
  }

  @media ${device.desktop} {
    height: 100vh;
  }
`;

export const TransitionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(5, auto);
  z-index: 1000;
  pointer-events: none;

  .whiteBlock {
    animation: ${(props: PageProps) =>
      props.animation
        ? css`
            ${fadeIn} 300ms linear
          `
        : css`
            ${slideToRight} 300ms ease-in
          `};
    animation-fill-mode: forwards;
    animation-delay: ${(props: PageProps) => !props.animation && "0.7s"};

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(19) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.1s"};
    }

    &:nth-child(0),
    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(16) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.4s"};
    }
  }
`;

export const TransitionBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: auto;
  background: #ffffff;
  z-index: 1;
`;
